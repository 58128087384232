/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-fill-check': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m1.679-4.493l-1.335 2.226a.75.75 0 01-1.174.144l-.774-.773a.5.5 0 01.708-.708l.547.548 1.17-1.951a.5.5 0 11.858.514M11 5a3 3 0 11-6 0 3 3 0 016 0"/><path pid="1" d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 018 12.5a4.5 4.5 0 011.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>',
    },
});
